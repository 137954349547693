import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import './common/js/rem'
import './common/scss/reset.scss'

import tost from './components/Tost/tost'
import loading from './components/Loading/loading'

import { Tab, Tabs } from 'vant'
import 'vant/lib/index.css'

// import Vconsole from 'vconsole'

// // eslint-disable-next-line no-new
// new Vconsole()

const app = createApp(App)

app
  .use(tost)
  .use(loading)
  .use(router)
  .use(Tab)
  .use(Tabs)
  .mount('#app')
