import { createRouter, RouteRecordRaw, createWebHashHistory } from 'vue-router'
import Index from '../views/index.vue'
import Detail from '../views/detail.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/news',
    name: 'news',
    component: Detail
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, form, next) => {
  if (to.path) {
    console.log(';0-0-00-0-')
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
})
export default router
