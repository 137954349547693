import "core-js/modules/es.array.push.js";
import { defineComponent, getCurrentInstance, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { newList } from '../api/index';
import utils from '../common/js/utils';
export default defineComponent({
  setup() {
    const {
      appContext
    } = getCurrentInstance();
    const {
      $tost,
      $loading
    } = appContext.config.globalProperties;
    const router = useRouter();
    const channeId = ref(Number(utils.getParam('channel')));
    const adsChannelId = ref(null);

    if (location.origin === 'https://shranji.com') {
      switch (channeId.value) {
        case 3010712:
          adsChannelId.value = 'ULa1LL';
          break;

        case 3010713:
          adsChannelId.value = 'mIpNX8';
          break;

        default:
          adsChannelId.value = 'ULa1LL';
      }
    } else {
      switch (channeId.value) {
        case 3009826:
          adsChannelId.value = 'boV5fa';
          break;

        case 3009827:
          adsChannelId.value = 'F1zqV5';
          break;

        case 3010259:
          adsChannelId.value = 'HZoqV4';
          break;

        case 3010260:
          adsChannelId.value = 'YjJ463';
          break;

        default:
          adsChannelId.value = 'RPiF6S';
      }
    }

    console.log(adsChannelId.value);
    const active = ref(0);
    const scrollRef = ref(null);
    const ads360 = ref([]);

    const set360Ref = (el, ind) => {
      if (el) {
        ads360.value.push(el);
      }
    };

    const types = ref([{
      id: 'guonei',
      value: '国内'
    }, {
      id: 'guoji',
      value: '国际'
    }, {
      id: 'yule',
      value: '娱乐'
    }, {
      id: 'tiyu',
      value: '体育'
    }, {
      id: 'junshi',
      value: '军事'
    }, {
      id: 'keji',
      value: '科技'
    }, {
      id: 'caijing',
      value: '财经'
    }, {
      id: 'youxi',
      value: '游戏'
    }, {
      id: 'qiche',
      value: '汽车'
    }, {
      id: 'jiankang',
      value: '健康'
    }]);
    const news = ref([]);
    const params = ref({
      key: '34020ef1c0db1f4fcb986ae9d60e4501',
      type: 'guonei',
      page: 1,
      page_size: 30,
      is_filter: 1
    });

    function ads(idAd, id) {
      if (!(idAd % 2)) {
        const generateUUID = (length = 32) => {
          let result = '';
          const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
          const charactersLength = characters.length;

          for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
          }

          return result;
        };

        window.QIHOO_WAP_FEEDAD({
          w: 375,
          h: 140,
          placeholderId: id,
          showid: adsChannelId.value,
          adOptions: {
            queryword: '',
            title: '',
            uid: generateUUID() // 必填，标识PV的hash值

          },
          onFail: function (e) {
            console.log(id);
            console.log(e, '-fail-');
          },
          onSuccess: function () {
            console.log('-success-');
          },
          onClose: function () {
            console.log('-close-');
          }
        });
      }
    }

    var newLength = 0;

    function newads() {
      if (newLength === 0) {
        news.value.map((val, ind) => {
          ads(ind, types.value[active.value].id + 'newList' + ind);
        });
      } else {
        news.value.map((val, ind) => {
          if (ind >= newLength) {
            ads(ind, types.value[active.value].id + 'newList' + ind);
          }
        });
      }
    }

    function initNews(type) {
      newList(params.value).then(res => {
        console.log(res);

        if (res.error_code === 0) {
          if (type === 'init') {
            newLength = 0;
            news.value = [];
          }

          res.result.data.map(val => {
            if (val.thumbnail_pic_s) {
              news.value.push(val);
            }
          });
        }
      }).then(() => {
        newads();
        newLength = news.value.length - 1;
      });
    }

    initNews('init');

    const beforeChange = ind => {
      console.log(ind);

      window._hmt.push(['_trackEvent', 'tab', 'click', ind]);

      params.value.type = types.value[ind].id;
      params.value.page = 1;
      initNews('init');
      return true;
    };

    onMounted(() => {
      var dom = scrollRef.value.$el.children[1].children[0].children[0].children;

      for (let i = 0; i < dom.length; i++) {
        scrollRef.value.$el.children[1].children[0].children[0].children[i].addEventListener('scroll', handleScroll, true);
      }
    });
    const scrollTopVal = ref(0);

    const handleScroll = e => {
      scrollTopVal.value = e.target.scrollTop;
      const offsetHeight = e.target.offsetHeight;
      const scrollHeight = e.target.scrollHeight;

      if (scrollTopVal.value + offsetHeight >= scrollHeight - 5) {
        console.log('触底了');
        params.value.page++;
        initNews('scroll');
      }

      if (scrollTopVal.value === 0) {
        params.value.page = 0;
        initNews('init');
      }
    };

    const newsClickHandle = v => {
      router.push({
        path: '/news',
        query: {
          id: v.uniquekey,
          channel: channeId.value
        }
      });
    };

    return {
      active,
      scrollRef,
      set360Ref,
      newLength,
      news,
      types,
      beforeChange,
      newsClickHandle
    };
  }

});