import "core-js/modules/es.array.push.js";
import { defineComponent, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { newDetail, newList } from '../api/index';
import utils from '../common/js/utils';
export default defineComponent({
  name: '',

  setup() {
    const router = useRouter();
    const route = useRoute();
    const channeId = ref(Number(utils.getParam('channel')));
    const adsChannelId = ref(null);

    if (location.origin === 'https://shranji.com') {
      switch (channeId.value) {
        case 3010712:
          adsChannelId.value = 'ULa1LL';
          break;

        case 3010713:
          adsChannelId.value = 'mIpNX8';
          break;

        default:
          adsChannelId.value = 'ULa1LL';
      }
    } else {
      switch (channeId.value) {
        case 3009826:
          adsChannelId.value = 'boV5fa';
          break;

        case 3009827:
          adsChannelId.value = 'F1zqV5';
          break;

        case 3010259:
          adsChannelId.value = 'HZoqV4';
          break;

        case 3010260:
          adsChannelId.value = 'YjJ463';
          break;

        default:
          adsChannelId.value = 'RPiF6S';
      }
    }

    console.log(adsChannelId.value);
    const scrollCon = ref(null);
    const sytle = ref({
      height: window.innerHeight - 200 + 'px',
      overflow: 'hidden'
    });
    const news = ref();
    const content = ref({
      content: '',
      detail: {
        title: '',
        author_name: '',
        date: ''
      }
    });
    const params = ref({
      key: '34020ef1c0db1f4fcb986ae9d60e4501',
      uniquekey: route.query.id,
      type: 'guonei',
      page: 1,
      page_size: 30,
      is_filter: 1
    });

    function ads(idAd, id) {
      if (!(idAd % 2)) {
        const generateUUID = (length = 32) => {
          let result = '';
          const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
          const charactersLength = characters.length;

          for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
          }

          return result;
        };

        window.QIHOO_WAP_FEEDAD({
          w: 375,
          h: 140,
          placeholderId: id,
          showid: adsChannelId.value,
          adOptions: {
            queryword: '',
            title: '',
            uid: generateUUID() // 必填，标识PV的hash值

          },
          onFail: function (e) {
            console.log(id);
            console.log(e, '-fail-');
          },
          onSuccess: function () {
            console.log('-success-');
          },
          onClose: function () {
            console.log('-close-');
          }
        });
      }
    }

    newDetail(params.value).then(res => {
      if (res.error_code === 0) {
        content.value = res.result;
        content.value.detail.author_name = content.value.detail.author_name.split('，')[0];
      }
    }).then(() => {
      ads(0, 'detiads');
    });
    var newLength = 0;

    function newads() {
      if (newLength === 0) {
        news.value.map((val, ind) => {
          ads(ind, 'newDeti' + ind);
        });
      } else {
        news.value.map((val, ind) => {
          if (ind >= newLength) {
            ads(ind, 'newDeti' + ind);
          }
        });
      }
    }

    function initNews() {
      newList(params.value).then(res => {
        if (res.error_code === 0) {
          newLength = 0;
          news.value = [];
          res.result.data.map(val => {
            if (val.thumbnail_pic_s) {
              news.value.push(val);
            }
          });
        }
      }).then(() => {
        newads();
        newLength = news.value.length - 1;
      });
    }

    initNews();

    const showConentHandle = () => {
      sytle.value.overflow = 'normal';
      sytle.value.height = 'auto';
    };

    const newsClickHandle = v => {
      router.replace({
        path: '/news',
        query: {
          id: v.uniquekey,
          channel: channeId.value
        }
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    };

    return {
      sytle,
      content,
      route,
      news,
      scrollCon,
      showConentHandle,
      newsClickHandle
    };
  }

});