import request from '@/common/js/request'

// baseURL:
// const api =
//   process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL : '/api'

const api =
  process.env.NODE_ENV === 'production' ? '' : '/api'

export async function newList (params:any): Promise<unknown> {
  return await request.get(`/toutiao/index?key=${params.key}&type=${params.type}&page=${params.page}&page_size=${params.page_size}&is_filter=${params.is_filter}`,
    {
      baseURL: api,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
      }
    }
  )
}

export async function newDetail (params:any): Promise<unknown> {
  return await request.get(`/toutiao/content?key=${params.key}&uniquekey=${params.uniquekey}`,
    {
      baseURL: api,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
      }
    }
  )
}
