
export default {
  getParam (name:string): string | null {
    // 获取连接参数
    // eslint-disable-next-line prefer-rest-params
    const u = arguments[1] || unescape(window.location.href)
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    const r = u.substr(u.indexOf('?') + 1).match(reg)
    return r != null ? decodeURI(r[2]) : ''
  }
}
