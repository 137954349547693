import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "wrap"
};
const _hoisted_2 = {
  class: "new-content"
};
const _hoisted_3 = {
  class: "news-left"
};
const _hoisted_4 = {
  class: "news-text"
};
const _hoisted_5 = {
  class: "news-tip"
};
const _hoisted_6 = {
  class: "news-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_tab = _resolveComponent("van-tab");

  const _component_van_tabs = _resolveComponent("van-tabs");

  return _openBlock(), _createBlock("div", _hoisted_1, [_createVNode(_component_van_tabs, {
    active: _ctx.active,
    "onUpdate:active": _cache[1] || (_cache[1] = $event => _ctx.active = $event),
    swipeable: "",
    ref: "scrollRef",
    onChange: _ctx.beforeChange
  }, {
    default: _withCtx(() => [(_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.types, (val_, ind_) => {
      return _openBlock(), _createBlock(_component_van_tab, {
        title: val_.value,
        key: ind_
      }, {
        default: _withCtx(() => [_createVNode("div", _hoisted_2, [_createVNode("ul", null, [(_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.news, (val, ind) => {
          return _openBlock(), _createBlock("li", {
            key: ind
          }, [_createVNode("div", {
            id: val_.id + 'newList' + ind,
            ref: _ctx.set360Ref
          }, null, 8, ["id"]), _createVNode("div", {
            class: "news-list",
            onClick: $event => _ctx.newsClickHandle(val)
          }, [_createVNode("div", _hoisted_3, [_createVNode("p", _hoisted_4, _toDisplayString(val.title), 1), _createVNode("p", _hoisted_5, _toDisplayString(val.author_name), 1)]), _createVNode("div", _hoisted_6, [val.thumbnail_pic_s ? (_openBlock(), _createBlock("img", {
            key: 0,
            src: val.thumbnail_pic_s,
            alt: ""
          }, null, 8, ["src"])) : _createCommentVNode("", true)])], 8, ["onClick"])]);
        }), 128))])])]),
        _: 2
      }, 1032, ["title"]);
    }), 128))]),
    _: 1
  }, 8, ["active", "onChange"])]);
}