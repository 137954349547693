import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

// 创建axios实例
const service = axios.create({
  timeout: 10000
})
// request拦截器
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
